import * as React from "react";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header/Header";
import ContactForm from "../components/ContactPageForm/ContactForm";
import SendToFriend from "../components/ContactPageForm/SendToFriend";
import ListMyProperty from "../components/ContactPageForm/ListMyProperty";
import CareerForm from "../components/ContactPageForm/CareerForm";
import BookingForm from "../components/ContactPageForm/BookingForm";
import MortgageForm from "../components/ContactPageForm/MortgageForm";
import Footer from "../components/Footer/Footer";
import "../pages/ContactPage/ContactPage.scss";
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import {TeamContact} from "../queries/common_use_query"
const FormTemplate = (props) => {
    function stripTrailingSlash(str){
        if(str.charAt(str.length-1) == "/"){ str = str.substr(0, str.length - 1);}
        return str
     }
     
    const GQLPage = props.GQLPage
    const GQLModules = props.GQLModules
    const location = useLocation();    
    const searchParams = parse(location.search) // => {init: "true"}
    const teamid = searchParams.tid && searchParams.tid.replace(/\/$/, '');
    const {loading:loading, error:error, data:data} = TeamContact(teamid);


    return (
        <>
            <div className="contact-page">
                <Header />
                {GQLModules.map((Modules, i) => {
                    return <>
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "List_My_Property" &&
                            <ListMyProperty data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content} formname={Modules.Select_Form}/>
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Contact" &&
                            <ContactForm data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content} page={GQLPage}  formname={Modules.Select_Form}/>
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Send_to_Friend" &&
                            <SendToFriend data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content} formname={Modules.Select_Form} />
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Job_Apply" &&
                            <CareerForm data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content} formname={Modules.Select_Form} />
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Book_an_Appointment" &&
                            <MortgageForm data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content} formname={Modules.Select_Form}/>
                        }
                        {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Form === "Book_a_Viewing" &&
                            <BookingForm data={data} title={GQLPage.Banner_Title} content={GQLPage.Banner_Content}formname={Modules.Select_Form} />
                        }
                    </>
                })}

                
         <Footer popularsearch={GQLPage.Select_Popular_Search} />

            </div>
        </>
    );
};
export default FormTemplate;
