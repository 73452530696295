import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./ContentWithSidebar.scss"
import Sticky from "react-stickynode"

import parse from "html-react-parser"
import Sidebar from "./Sidebar"
import BookACall from "../forms/book-a-call"
import { Link } from "gatsby"

const ContentWithSidebar = (props) => {
  return (
    <div className="areaguides-detail-container content-sidebar">
      <Container>
        <Row>
          <Col md={12} lg={8} xl={8}>
            {props.Content_with_Image && props.Content_with_Image.map((item, key) => {
              return (
                <div className="areaguides-detail-content" key={key}>
                  <div className="above-content">
                    {item.Title &&
                      <h3>{parse(item.Title)}</h3>
                    }
                    {item.Content &&
                      <>{parse(item.Content)}</>
                    }
                    {item.Image &&
                      <img
                        src={item.Image.url}
                      />
                    }
                  </div>

                </div>
              )
            })}
            <div className="stop-sticky"></div>
          </Col>
          <Col lg={4} xl={4}>
            <Sticky top=".header" bottomBoundary=".stop-sticky">
              <div className="sidebar">
                <h3>{props.Sidebar_Title}</h3>
                <p>{props.Sidebar_Content}</p>
                <div className="forms">
                  <BookACall to_email_id={"pm@mcconeproperties.com"}/>
                </div>
              </div>
            </Sticky>

          </Col>
        </Row>
      </Container>
      <div className="sidebar outer-sidebar">
        <h3>{props.Sidebar_Title}</h3>
        <p>{props.Sidebar_Content}</p>
        <div className="forms">
          <BookACall to_email_id={"pm@mcconeproperties.com"}/>
        </div>
      </div>
    </div>
  )
}

export default ContentWithSidebar
