import React, {useEffect} from "react"
import "./NewsModal.scss"
import NewsletterForm from "../forms/newsletter-page-form"
const NewsModal = ({isModalOpen, closeModal}) => {
    if (!isModalOpen) {
        return null
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        closeModal();
    }

  return (
    <div className="news-model-wrapper">
      <div className="news-model-container">
        <h3 className="title">Newsletter</h3>
        <p className="description">
        Join our newsletter and never miss an update about the Dubai property market. 
        </p>
        <div className="form-container">
          <NewsletterForm />
          {/* <form onSubmit={onFormSubmit}>
            <div className="form-group">
              <div className="input-container">
                <label htmlFor="name">Your Name</label>

                <input className="form-name" type="text" placeholder="Name" />
              </div>
              <div className="input-container">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  className="form-email"
                  placeholder="Email address"
                />
              </div>
              <div className="submit-btn">
                <button className="btn" type="submit">
                  Subscribe <i class="icon-right"></i>
                </button>
              </div>
            </div>
          </form> */}
          <div className="close-btn" onClick={closeModal}>
            <i class="icon-close"></i>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={closeModal}></div>
    </div>
  )
}

export default NewsModal
