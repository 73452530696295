import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SubMenuCard from './SubMenuCard'
import "./SubMenuListing.scss"

const AreaguidesListing = (props) => {
//console.log("🚀 ~ file: SubMenuListing.js ~ line 7 ~ AreaguidesListing ~ props", props)

    const subMenus = props.module.Sub_Menu_Listing
    
  return (
    <div className="submenu-listing-wrapper">
      <div className="container">
        <div className="submenu-listing">
          {subMenus.map(subMenu => (
            <SubMenuCard newsCard={subMenu} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AreaguidesListing