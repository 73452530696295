import React from "react"
import "./OurPackages.scss"
import Slider from "react-slick"
import PackageCard from "./PackageCard"
import parse from 'html-react-parser';

const OurPackages = ({ module }) => {
  const { Module_Description, Module_Items, Module_Title } = module
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="packages-wrapper">
      <div className="container">
        <div className="packages-content">
          <div className="packages-intro">
            <h3 className="title">{Module_Title}</h3>
            <div className="description">{parse(Module_Description)}</div>
          </div>
          <div className="packages-cards">
            <Slider {...settings}>
              {Module_Items.map((item, index) => (
                <PackageCard item={item}/>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPackages
