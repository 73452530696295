/**
 * Rental Yield Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Form, Button } from "react-bootstrap"
import { filterNumber, numberFormat, rentalYield } from "./util"
import { defaultValues } from "./config"
// use project specific import here

// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  const {
    propertyPrice,
    monthlyRent,
    currency,
    instantResult,
    pricePrefix,
    title,
  } = props

  const prefix = "AED "
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [serviceCharge, setServiceCharge] = useState(
    prefix + numberFormat(0)
  )
  const [additionalCharge, setAdditionalCharge] = useState(
    prefix + numberFormat(0)
  )
  const [rent, setRent] = useState(prefix + numberFormat(monthlyRent))
  const [result, setResult] = useState("")
  const [netRent, setNetRent] = useState(
    prefix + numberFormat(0)
  )

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
  }

  const handleService = event => {
    let val = filterNumber(event.target.value)
    setServiceCharge(prefix + numberFormat(val))
  }
  const handleAdditional = event => {
    let val = filterNumber(event.target.value)
    setAdditionalCharge(prefix + numberFormat(val))
  }

  const handleRent = event => {
    let d = filterNumber(event.target.value)
    setRent(prefix + numberFormat(d))
  }

  const getResult = (purchasePrice, rent) => {
    let result = rentalYield(filterNumber(purchasePrice), (filterNumber(rent) - filterNumber(serviceCharge) - filterNumber(additionalCharge)))
    setResult(result.toFixed(2))
    setNetRent(prefix + numberFormat(filterNumber(rent) - filterNumber(serviceCharge) - filterNumber(additionalCharge)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Rental Yield Calculator",
        'formName': "Rental Yield Calculator",
        'formLabel': "Rental Yield Calculator"
      });

      if (filterNumber(purchasePrice) && filterNumber(rent)) {
        getResult(purchasePrice, rent)
      }
    }
  }

  useEffect(() => {
    //if (instantResult) {
    getResult(purchasePrice, rent)
    //}
  }, [instantResult, purchasePrice, rent, serviceCharge, additionalCharge])

  // DO NOT DO ANY CHNAGES - END

  return (
    <div className="form mortgate-form">
      {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required | invalid</p>
        </div>
      )}
      <Form
        name="RentalyieldCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Row>
          <Col md={6}>
            <Form.Group controlId="purchase_price">
              <div className="custom-float">
                <Form.Label className="form-field-label">Purchase Price:</Form.Label>
                <Form.Control
                  className="form-control"
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="purchase_price">
              <div className="custom-float">
                <Form.Label className="form-field-label">Annual Service Charge:</Form.Label>
                <Form.Control
                  className="form-control"
                  required
                  type="text"
                  name="service_charge"
                  value={serviceCharge}
                  placeholder="Annual Service Charge"
                  onChange={handleService}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>

            <Form.Group controlId="purchase_price">
              <Form.Label className="form-field-label">Additional Charges:</Form.Label>
              <Form.Control
                className="form-control"
                required
                type="text"
                name="additional_charge"
                value={additionalCharge}
                placeholder="Additional Charge"
                onChange={handleAdditional}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="rent">
              <Form.Label className="form-field-label">Annual Rental Price:</Form.Label>
              <Form.Control
                className="form-control"
                required
                type="text"
                name="monthly_rent"
                value={rent}
                placeholder="Monthly Rent"
                onChange={handleRent}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* <Col md={5}>
            <Button type="submit" className="btn btn-pink">
              Calculate
            </Button>
          </Col> */}
          <Col md={4}>
            {result && (
              <div className="monthly-payment">
                <h3>Net Rent</h3>
                <strong className="pay d-block monthly-amount">{netRent}</strong>
              </div>
            )}
          </Col>
          <Col md={6}>
            {result && (
              <div className="monthly-payment">
                <h3>NET ROI</h3>
                <strong className="pay d-block monthly-amount">{result}%</strong>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}

RentalyieldCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  monthlyRent: defaultValues.MONTHLY_RENT,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  instantResult: defaultValues.INSTANT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
}

RentalyieldCalc.propTypes = {
  propertyPrice: PropTypes.any,
  monthlyRent: PropTypes.any,
  currency: PropTypes.string,
  instantResult: PropTypes.any,
  pricePrefix: PropTypes.any,
}

export default RentalyieldCalc