import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import $ from "jquery";
//import parse from "html-react-parser"
import OffPlanContactForm from "../ContactForm/OffPlanContactForm";
import "./OffPlanContact.scss";
const OffPlanContact = (props) => {
  const module = props.module
  const [readMore, setReadMore] = useState(module.Sidebar_Content.length >= 1165)

  const readmore_expand = (readMore) => {
    setReadMore(!readMore)
    if(!readMore) {
      $('html, body').animate({scrollTop: $(".description-text").offset().top - 200}, 1000);
    }
  }

  return (
    <>
      <section className="contact-wrap-offplan">
        <Container>
          <Row>
            <Col>
                <h2 className="contact-mccone-h2">{module.Sidebar_Title}</h2>
                <div className="description-text">
                  <div className={`details-info property-description-details ${readMore ? "read_more" : "read_less"}`}>

                  {module.Sidebar_Content &&
                      <div className={`expand ${readMore ? "panel-closed" : ""}`}>
                      {readMore ?
                      <>{HTMLReactParser(module.Sidebar_Content.substr(0, 1165))}</>
                      :
                      <>{HTMLReactParser(module.Sidebar_Content)}</>
                      }
                      </div>
                  }
                  {module.Sidebar_Content.length >= 1165 &&
                      <a href="javascript:void(0)" className="readmore_expand" onClick={()=>readmore_expand(readMore)}>{readMore ? "Read more" : "Read Less"}</a>
                  }
                  </div>

                {/*parse(module.Sidebar_Content)*/}
                </div>
                {/*windowWidth < 992 ? (
                  <>
                    <Col>
                      <ContactForm />
                    </Col>
                  </>
                ) : null*/}
            </Col>
            <Col>
                    {module.Form_Title && (<h2 className="form-title">{module.Form_Title}</h2>)}
                    <OffPlanContactForm />
                </Col>

            { /*windowWidth >= 992 ? (
              <>
                <Col>
                    <ContactForm />
                </Col>
              </>
            ) : null*/}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default OffPlanContact;
