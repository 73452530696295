import * as React from "react"
import Header from "../components/Header/Header"
import Home from "../pages/Home/Home"
import "slick-carousel/slick/slick.css"
import Footer from "../components/Footer/Footer"
import Sticky from "../components/Sticky-Btn/Sticky"
import PropertyList from "../components/PropertyList/PropertyList"
import PropertyFeatures from "../components/PropertyFeatures/PropertyFeatures"
import HomePropertyFeatures from "../components/PropertyFeatures/HomePropertyFeatures"
import RealEstate from "../components/RealEstate/Estate"
import Team from "../components/OurTeam/Team.js"
import Career from "../components/Careers/Careers"
import NewsDetails from "../components/NewsDetails/NewsDetails"
import Banner from "../components/BannerComp/Banner"
import ReviewTiles from "../components/BannerComp/reviewtiles"
import Help from "../components/Help/Help"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import { Container, Row, Col } from "react-bootstrap"
import SelectAreaGuides from "../components/SelectAreaGuides/SelectAreaGuides"
import Steps from "../components/Steps/Steps"
import Brands from "../components/Lenders/Brands"
import TileBlockWhite from "../components/TileBlockWhite/Estate"
import Mortgage from "../components/MortgageModule/MortgageModule"
import Faq from "../components/FaqList/Faq"
import Contact from "../components/Contact/Contact"
import SelectReasons from "../components/Reasons/Reaons"
import TwoColumns from "../components/TwoColumns/TwoColumns"
import OurPackages from "../components/OurPackages/OurPackages"
import AreaguidesListing from "../components/AreaguidesListing/AreaguidesListing"
import CurrentOpenings from "../components/CurrentOpenings/CurrentOpenings"
import TeamListing from "../components/TeamListing/TeamListing"
import AwardsListing from "../components/AwardsListing/AwardsListing"
import NewsListing from "../components/NewsListing/NewsListing"
import SocialWall from "../components/SocialWall/SocialWall"
import StaticPageModule from "../components/StaticPageModule/StaticPageModule"
import RentalYield from "../components/MortgageRepayments/RentalYield"
import VideoBox from "../components/VideoBox/VideoBox"
import SubMenuListing from "../components/SubMenuListing/SubMenuListing"
import SelectPropertyFeatures from "../components/PropertyFeatures/SelectPropertyFeatures"
import ContentWithSidebar from "../components/ContentWithSidebar/ContentWithSidebar"
import ElfsightReviewsList from "../components/Reviews/Elfsight/ElfsightReviewsList.js"
// markup
const IndexPage = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules

  return (
    <>
      <div className={`page-wrapper ${GQLPage.Page_Class}`}>
        <Header layout={GQLPage.Layout} />
        {GQLPage.Banner_Type === "Small_Banner" ? (
          <Banner
            title={GQLPage.Banner_Title}
            multi_banner_title={GQLPage.Multi_Banner_Title}
            search={GQLPage.Show_Search}
            buttons={GQLPage.Banner_CTA}
            reviews={GQLPage.Banner_Reviews}
            overlaybanner={GQLPage.Banner_Overlay_Bg}
            bannerimage={GQLPage.Banner_Image}
            imagetransforms={GQLPage.imagetransforms}
            layout={GQLPage.Layout}
            id={GQLPage.id}
            video={GQLPage.Banner_Video_Link}
            content={GQLPage.Banner_Content}
            pageTitle={GQLPage.Title}
          />
        ) : (
          <Home
            title={GQLPage.Banner_Title}
            multi_banner_title={GQLPage.Multi_Banner_Title}
            search={GQLPage.Show_Search}
            reviews={GQLPage.Banner_Reviews}
            buttons={GQLPage.Banner_CTA}
            overlaybanner={GQLPage.Banner_Overlay_Bg}
            bannerimage={GQLPage.Banner_Image}
            imagetransforms={GQLPage.imagetransforms}
            layout={GQLPage.Layout}
            id={GQLPage.id}
            video={GQLPage.Banner_Video_Link}
            pageTitle={GQLPage.Title}
            content={GQLPage.Banner_Content}
            page={GQLPage}
          />
        )}

        {GQLModules.map((Modules, i) => {
          return (
            <>
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsThumbnailGrid" && (
                <>
                  <section className="property-list ">
                    <Container>
                      <Row>
                        {GQLPage.Title !== "Home" && (
                          <>
                            <h2 className="property-list-heading">
                              {Modules.Thumbnail_Grid_Heading}
                            </h2>
                          </>
                        )}
                        <Col md={12} className="d-md-flex list-wrap">
                          {Modules.Grid_List &&
                            Modules.Grid_List.map(property => (
                              <PropertyList
                                key={property.id}
                                propertyValue={property}
                              />
                            ))}
                        </Col>
                      </Row>
                    </Container>
                  </section>
                </>
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsVideoReviewBlocks" && (
                <ReviewTiles items={Modules.Review_Block_Items} />
              )}
              {(Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSelectFeaturedProperties" || 
                Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSelectReserevedProperties") && (
                <SelectPropertyFeatures module={Modules} type={Modules.__typename}/>
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                GQLPage.Title !== "Home" &&
                Modules.Select_Module === "Featured_Properties" && (
                  <PropertyFeatures />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                GQLPage.Title == "Home" &&
                Modules.Select_Module === "Featured_Properties" && (
                  <HomePropertyFeatures />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Reviews_Listing" && <ElfsightReviewsList />}
                {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "We_are_on_instagram_Module" && (
                  // <InstagramModule />
                  ""
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsFullWidthBlueBlock" && (
                <RealEstate
                  heading={Modules.Block_Heading}
                  content={Modules.Block_Content}
                  image={Modules.Block_Image}
                  videolink={Modules.Block_Video_URL}
                  ctas={Modules.Block_CTA}
                />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsTileBlock" && (
                <TileBlockWhite
                  heading={Modules.Tile_Title}
                  content={Modules.Tile_Content}
                  image={Modules.Tile_Image}
                  // videolink={Modules.Block_Video_URL}
                  ctas={Modules.Tile_CTA}
                  imagetransforms={GQLPage.imagetransforms}
                  id={Modules.id}
                />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Team_Slider" && <Team />}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsImageBlocks" && (
                <Career items={Modules.Image_Block_Items} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsTileBlockCounter" && (
                <Steps items={Modules.Tile_Block_Slides} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsInteriorDesignPackage" && (
                <OurPackages module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "News_Slider_Two_Column" && (
                  <NewsDetails />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSelectAreaguides" && (
                <SelectAreaGuides module={Modules} />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsFaq" && Modules.FAQ_List && (
                <Faq faqs={Modules.FAQ_List} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsTwoColumnContent" && (
                <TwoColumns module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSubTiles" && (
                <SelectReasons module={Modules} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Lenders_Module" && <Brands />}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Areaguieds_Listing" && (
                  <AreaguidesListing />
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Contact_Mccone_Form" && <Contact />}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Mortgage_Calculator_Module" && (
                  <Mortgage />
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Current_Openings" && (
                  <CurrentOpenings />
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Team_Listing" && <TeamListing />}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Awards_Listing" && <AwardsListing />}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "News_Listing" && <NewsListing />}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsStaticPage" && (
                <StaticPageModule module={Modules} pageData={GQLPage} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsLendersWeWorkWith" && (
                <Brands module={Modules} pageData={GQLPage} />
              )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsSubMenuModule" && (
                <SubMenuListing module={Modules} pageData={GQLPage} />
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsVideoBox" && (
                <div className="video-box-module">
                  <VideoBox
                    title={Modules.Title}
                    bannerimage={Modules.Video_Thumbnail}
                    imagetransforms={Modules.imagetransforms}
                    id={Modules.id}
                    video={Modules.Video_URL}
                  />
                </div>
              )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Lets_Work_Together_Module" && (
                  <Help module={Modules.Select_Module} />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Book_a_Valuation_Module" && (
                  <Help module={Modules.Select_Module} />
                )}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Rental_Yield_Calculator_Module" && (
                  <div
                    className="container rental-yeild-module"
                    id="rental-yeild-calculator"
                  >
                    <RentalYield price={1000000} />
                  </div>
                )}

              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsGlobalModules" &&
                Modules.Select_Module === "Social_Wall" && <SocialWall />}
              {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsContentWithSidebar" && <ContentWithSidebar {...Modules} />}
            </>
          )
        })}

        <Footer popularsearch={GQLPage.Select_Popular_Search} />
        <Sticky />
      </div>
    </>
  )
}

export default IndexPage
