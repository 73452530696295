import React, { useEffect } from "react"
import { useWindowSize } from "../../hooks/window-size"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import ContactDetails from "../ContactDetails/ContactDetails"
import MemberCard from "../MemberCard/MemberCardCareerForm"
import StaffCard from "../MemberCard/StaffCard"
import ContactForms from "../ContactPageForm/InputData"
import ContactForm from "../forms/career-form"
import { AnimationOnScroll } from "react-animation-on-scroll"
import "./Form.scss"
import { propTypes } from "react-bootstrap/esm/Image"
import parse from "html-react-parser"
import "../ContactPageForm/InputData.scss"
import { Helmet } from "react-helmet"

const Connect = props => {
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setTimeout(function () {
  //       window.rec_embed_js.load({
  //         widget_id: "rec_job_listing_div",
  //         page_name: "Careers",
  //         source: "CareerSite",
  //         site: "https://mccone.zohorecruit.com",
  //         empty_job_msg: "No current Openings",
  //       })
  //     }, 500)
  //   }
  // }, [])

  const siteUrl = process.env.GATSBY_SITE_URL
  const [windowWidth] = useWindowSize()
  const ref = React.useRef()
  const [height, setHeight] = React.useState("500px")

  var staffname = ""
  var staffemail = ""
  var teamdata = ""
  if (props.data && props.data.teams && props.data.teams.length > 0) {
    staffname = props.data.teams ? props.data.teams[0].Name : ""
    staffemail = props.data.teams ? props.data.teams[0].Email : ""
    teamdata = props.data?.teams.length > 0 ? props.data.teams[0] : ""
  }
  // if (typeof document !== "undefined") {
  //   var jobTitleLinks = document.querySelectorAll(".rec-job-title a")

  //   jobTitleLinks.forEach(function (link) {
  //     link.setAttribute("target", "_blank")
  //   })
  // }

  const waMsg =
    "Hi there, I would like to apply for a position open at McCone Properties."

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.0/embed_jobs.js"
        ></script>
      </Helmet>
      <section className="contact-wrap connect career-form">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-wrap">
                <div className="content-wrap">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="contact-title">
              <h1>
                {staffname && props.formname != "Job_Apply"
                  ? "Contact " + staffname
                  : props.title}
              </h1>
              {parse(props.content)}
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="form-field-wrapper">
                <ContactForm to_email_id={staffemail}/>
            </Col>
            <Col lg={4} className="card-field-wrapper">
              {staffemail ?
              <StaffCard staffdata={teamdata} waMsg={waMsg}/>
              :
              <MemberCard waMsg={waMsg}/>
              }
              <ContactDetails />
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12} className="form-field-wrapper">
              <ContactForm to_email_id={staffemail}/> 
             <div class="embed_jobs_head embed_jobs_with_style_3 embed_jobs_with_style">
                <div class="embed_jobs_head2">
                  <div class="embed_jobs_head3">
                    <div id="rec_job_listing_div"> </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={6} md={12} className="card-field-wrapper">
              {staffemail ? (
                <StaffCard staffdata={teamdata} waMsg={waMsg} />
              ) : (
                <MemberCard waMsg={waMsg} />
              )}
            </Col>
            <Col
              lg={6}
              md={12}
              className="card-field-wrapper contact-details-content"
            >
              <ContactDetails />
            </Col>
          </Row>*/}
        </Container>
      </section>
    </>
  )
}
export default Connect
