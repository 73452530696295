import React from 'react'
import MortgageForm from "./MortgageForm"
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./MortgageForm.scss";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"

const MortgageModule = () => {
  return (
    <div className="awards-mortgage awards-wrapper" id="mortgage-calculator">
      <Container>
        <Row>
          <Col md={12} className="d-xl-flex justify-content-between">
            <div className="title-mortgage">
              <h2>Calculate your mortgage repayments</h2>
              <p>
              Our Mortgage Calculator in Dubai helps estimate the monthly payment due along with other financial costs associated with mortgages. There are options to include extra payments or annual percentage increases of common mortgage-related expenses. Use this UAE mortgage calculator to plan and budget for your home.
              </p>
            </div>
            <MortgageForm />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MortgageModule