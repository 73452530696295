import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Dropdown } from "react-bootstrap";
import mortgageLogo from "../../images/propertyDetails/mortgage-logo.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Select from 'react-select'
import "./MortgageForm.scss";
import PropTypes from "prop-types"
import { filterNumber, numberFormat, rentalYield } from "../calculators/rentalyieldcalc/util"
import { defaultValues } from "../calculators/rentalyieldcalc/config"

const RentalyieldCalc = (props) => {
 
    const {
        propertyPrice,
        monthlyRent,
        currency,
        instantResult,
        pricePrefix,
        title,
      } = props
    
      const prefix = "AED "
      const [validated, setValidated] = useState(false)
      const [showerror, setShowerror] = useState(false)
      const [purchasePrice, setPurchasePrice] = useState(
        prefix + numberFormat(props.price?props.price:propertyPrice)
      )
      const [serviceCharge, setServiceCharge] = useState(
        prefix + numberFormat(0)
      )
      const [additionalCharge, setAdditionalCharge] = useState(
        prefix + numberFormat(0)
      )
      const [rent, setRent] = useState(prefix + numberFormat(monthlyRent))
      const [result, setResult] = useState("")
      const [netRent, setNetRent] = useState(
        prefix + numberFormat(0)
      )
    
      const handlePrice = event => {
        let val = filterNumber(event.target.value)
        setPurchasePrice(prefix + numberFormat(val))
      }
    
      const handleService = event => {
        let val = filterNumber(event.target.value)
        setServiceCharge(prefix + numberFormat(val))
      }
      const handleAdditional = event => {
        let val = filterNumber(event.target.value)
        setAdditionalCharge(prefix + numberFormat(val))
      }
    
      const handleRent = event => {
        let d = filterNumber(event.target.value)
        setRent(prefix + numberFormat(d))
      }
    
      const getResult = (purchasePrice, rent) => {
        let result = rentalYield(filterNumber(purchasePrice), (filterNumber(rent) - filterNumber(serviceCharge) - filterNumber(additionalCharge)))
        setResult(result.toFixed(2))
        setNetRent(prefix + numberFormat(filterNumber(rent) - filterNumber(serviceCharge) - filterNumber(additionalCharge)))
      }
    
      const handleSubmit = event => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity() === false) {
          setShowerror(true)
          setValidated(true)
        } else {
          if (filterNumber(purchasePrice) && filterNumber(rent)) {
            getResult(purchasePrice, rent)
          }
        }
      }
      const url = typeof window !== 'undefined' ? window.location.href : '';
      const trackevent = () => {
        if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              'event': 'Rental Yield Calculator',
              'formType': url,
              'formId': 'Rental Yield Calculator',
              'formName': 'Rental Yield Calculator',
              'formLabel': 'Rental Yield Calculator'
          });
        }
      }
      useEffect(() => {
        //if (instantResult) {
        trackevent()
        getResult(purchasePrice, rent)
        //}
      }, [instantResult, purchasePrice, rent, serviceCharge, additionalCharge])
    
      // DO NOT DO ANY CHNAGES - END
  return (
    <>
        <form onSubmit={handleSubmit} validated={validated}>
          <div className="payment-card">
            <div className="card-left">
              <div className="input-wrap">
                <label>Purchase Price:</label>
                <input
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                />
              </div>
              <div className="input-wrap">
                <label>Annual Service Charge:</label>
                <input
                  required
                  type="text"
                  name="service_charge"
                  value={serviceCharge}
                  placeholder="Annual Service Charge"
                  onChange={handleService}
                  maxLength={21}
                />
              </div>
              <div className="input-wrap">
                <label>Additional Charges:</label>
                <input
                  type="text"
                  name="additional_charge"
                  value={additionalCharge}
                  placeholder="Additional Charge"
                  onChange={handleAdditional}
                />              </div>
              <div className="input-wrap">
                <label>Annual Rental Price:</label>
                <input
                  type="text"
                  name="monthly_rent"
                  value={rent}
                  placeholder="Monthly Rent"
                  onChange={handleRent}
                />
                </div>
              {/* <div className="mortgage-detail">
                <div className="img-wrap">
                  <img src={mortgageLogo} alt="logo" />
                </div>
                <span className="text-wrap">
                  <span className="md d-md-none">Powered by </span>
                  <span className="dd d-none d-md-inline-block">In partnership with </span> <Link to="/">Mena Mortgage Services</Link>
                </span>
              </div> */}
            </div>
            <div className="card-right">
              <div className="res--block">
              <div>
              <span className="payment-text">Net Rent</span>
              <span className="price-wrap">{netRent}</span>
              </div>
              <div>
              <span className="payment-text">NET ROI</span>
              <span className="price-wrap">{result}%</span>
              </div>
              </div>
              <span className="cta-text">Speak with our broker</span>
              <div className="btn-wrap">
                <Link to="/book-an-appointment/" className="btn">
                  Book an appointment
                  <i className="icon-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </form>
    </>
  );
};
RentalyieldCalc.defaultProps = {
    propertyPrice: defaultValues.PROPERTY_VALUE,
    monthlyRent: defaultValues.MONTHLY_RENT,
    currency: process.env.CURRENCY
      ? process.env.CURRENCY
      : defaultValues.CURRENCY,
    instantResult: defaultValues.INSTANT_RESULT,
    pricePrefix: defaultValues.PRICE_PREFIX,
  }
  
  RentalyieldCalc.propTypes = {
    propertyPrice: PropTypes.any,
    monthlyRent: PropTypes.any,
    currency: PropTypes.string,
    instantResult: PropTypes.any,
    pricePrefix: PropTypes.any,
  }
export default RentalyieldCalc;
