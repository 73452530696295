import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ElfsightWidget from './ElfsightWidget';

const ElfsightReviewsList = () => (
  <React.Fragment>
    <Container className="reviews-listing">
      <Row>
        <div className="reviews-head">
          <div className="review-head-left">
            <h2>Real people. Real reviews.</h2>
            <p>We put our clients first here at McCone Properties.</p>
          </div>
          <div className="write-review">
            <a href="https://g.page/r/CQWW0oxCTuyGEAg/review" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">
              Leave a Review<i className="icon-right"></i>
            </a>
          </div>
        </div>
        <div className="elfsight-reviews-wrap">
          <ElfsightWidget widgetID="746319fc-ceed-482d-bdcd-9f0672a97780" />
        </div>
      </Row>
    </Container>
  </React.Fragment>
);

export default ElfsightReviewsList;