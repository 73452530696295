import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import {useLocation} from "@reach/router"
import AeroDesk from "../../images/landing/aero.jpg"
import Car from "../../images/landing/car1800.jpg"
import CarTab from "../../images/landing/car1800.jpg"
import CarDesk from "../../images/landing/car1800.jpg"
import propertyTab02 from "../../images/landing/1802.jpg"
import DownTownDesk from "../../images/landing/1803.png"
import Location01 from "../../images/landing/place01.png"
import Location02 from "../../images/landing/place02.png"
import Location03 from "../../images/landing/place03.png"
import Location04 from "../../images/landing/place04.png"
import Location05 from "../../images/landing/place05.jpg"
import { useWindowSize } from "../../hooks/window-size"
import { graphql, useStaticQuery } from "gatsby"
import NewsModal from "../NewsModal/NewsModal"

import NewsCard from "./NewsCard"

import "./NewsListing.scss"

const NewsListing = () => {
  const data = useStaticQuery(graphql`
    query GetNews {
      glstrapi {
        newsCategories {
          id
          URL
          Name
        }
        newsInsights {
          Tile_Image {
            url
          }
          Description
          Title
          URL
          id
          imagetransforms
          Select_Category {
            id
            URL
            Name
          }
          Reading_Time
          News_Date
        }
      }
    }
  `)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  useEffect(() => {
    if (sessionStorage) {
      setTimeout(() => {
        const modalShown = sessionStorage.getItem("modalShown")
        if (!modalShown) {
          setIsModalOpen(true)
          sessionStorage.setItem("modalShown", "true")
        }
      }, 2000)
    }
  }, [])

  const newsCategories = data.glstrapi.newsCategories
  const newsInsights = data.glstrapi.newsInsights
  const location = useLocation()

  const [windowWidth] = useWindowSize()

  const [filterClose, setFilterclose] = useState(false)
  const filterBtn = () => {
    setFilterclose(!filterClose)
  }

  const [filter, setFilter] = useState("all")
  let [newsToShow, setNewsToShow] = useState([])

  useEffect(() => {
    // sort newsInsights by date
    const sortedNewsInsights = newsInsights.sort((a, b) => {
      return new Date(b.News_Date) - new Date(a.News_Date)
    })
    setNewsToShow(sortedNewsInsights)
  }, [])

  useEffect(() => {
    if (filter === "all") {
      setNewsToShow(newsInsights)
    } else {
      const filteredNews = newsInsights.filter(news => {
        return news.Select_Category.Name === filter
      })
      setNewsToShow(filteredNews)
    }
  }, [filter])

  var blogchemaList = [];
  newsInsights.map((item , i) =>{
    blogchemaList.push({
      "@type": "ListItem",
       "position" : i+1,
       "url":`${location?.href}${item?.URL}`
  })
  })
      
  //console.log("location",location)
 
  
  return (
    <div className="news-listing-section">
      
      <Helmet>
        <script type="application/ld+json">{`{
           "@context": "https://schema.org",
           "@type": "ItemList",
           "itemListElement": ${JSON.stringify(blogchemaList, null, 2)}
          
        }`} 

        </script>
      </Helmet>

     
      <NewsModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <section className="category-wrap">
        <Container>
          <Row className="RowMerge">
            <Col className="RowMerge">
              <button
                type="button"
                className="category-btn dropdown-toggle btn btn-primary"
                onClick={filterBtn}
              >
                {filter == "all" ? "all categories" : filter}

                <i className="drop-arrow"></i>
              </button>
              <nav className={`nav-wrap ${filterClose ? "nav-menu-open" : ""}`}>
                <ul className="nav-menu">
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "all" ? "filter-link active" : "filter-link"
                      }
                      active={filter === "all"}
                      onClick={() => {
                        setFilter("all")
                        setFilterclose(false)
                      }}
                    >
                      all news &amp; video
                    </button>
                  </li>
                  {newsCategories.map(category => {
                    if (
                      !newsInsights.find(
                        news => news.Select_Category.Name === category.Name
                      )
                    ) {
                      return null
                    }
                    return (
                      <>
                        <li>
                          <button
                            type="button"
                            className={
                              filter === category.Name
                                ? "filter-link active"
                                : "filter-link"
                            }
                            active={filter === category.Name}
                            onClick={() => {
                              setFilter(category.Name)
                              setFilterclose(false)
                            }}
                          >
                            {category.Name}
                          </button>
                        </li>
                      </>
                    )
                  })}
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="news-reads">
        <div className="news-reads-wrap news-listing-wrap">
          <Container>
            {/* {newsToShow[0] && <NewsCard key={newsToShow[0].id} newsCard={newsToShow[0]} />} */}
            <Row className="m-0 news-listing-row">
              {windowWidth >= 768 &&
                newsToShow.slice(0, 1).map(news => (
                  <Col className="p-0 col-12 top-card">
                    <NewsCard key={news.id} newsCard={news} bigCard />
                  </Col>
                ))}

              {windowWidth < 767 ? (
                <>
                  {newsToShow.map(news => (
                    <Col className="p-0 col-12 col-md-6 col-lg-4  medium-card ">
                      <NewsCard key={news.id} newsCard={news} />
                    </Col>
                  ))}
                </>
              ) : null}

              {windowWidth >= 768 && windowWidth <= 991 ? (
                <>
                  {newsToShow.slice(1).map(news => (
                    <Col className="p-0 col-6 col-md-6 col-lg-4 name789  medium-card ">
                      <NewsCard key={news.id} newsCard={news} />
                    </Col>
                  ))}
                </>
              ) : null}

              {windowWidth >= 992 ? (
                <>
                  {newsToShow.slice(1).map(news => (
                    <Col className="p-0 col-6  col-lg-4  medium-card ">
                      <NewsCard key={news.id} newsCard={news} />
                    </Col>
                  ))}
                </>
              ) : null}
            </Row>

            <Row className="m-0 row-cols-5 last-wrap">
              {windowWidth <= 1699 ? (
                <>
                  {newsToShow.slice(5, 9).map(property =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6 col-md-3 02 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
              {windowWidth > 1700 ? (
                <>
                  {newsToShow.slice(5).map(property =>
                    property.filtered === true ? (
                      <Col className=" p-0 col-6 col-md-3 03 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
            </Row>
          </Container>
        </div>
      </section>
    </div>
  )
}
export default NewsListing
