import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../PlayVideo/PlayVideo";
import { Link } from "gatsby";
import { useWindowSize } from "../../hooks/window-size";
import Slider from "react-slick";
import googleImg from "../../images/reviews/google.svg";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Banner.scss";
import "../BreadCrumbs/BreadCrumbs.scss";
import GetGatsbyImage from "../common/site/get-gatsby-image"

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  mobileFirst: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const Banner = (props) => {
  const [windowWidth] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [videourl, setVideourl] = useState(false);

  return (
    <section className="banner-wrap reviews-breadcrumb review--slider">
      <Container>
        <Row>
          <Col>

              {props.items ? (
                <div className="slider-wrap">
                  <Slider {...settings} className="btn-fix">
                    {props.items && props.items.map((reviewer, index) => (
                      <div className="slide" key={index}>
                        <div className="img-wrap img-zoom">
                          <picture>
                            <GetGatsbyImage
                                image={reviewer.Reviewer_Image}
                                fallbackalt={reviewer.Reviewer_Name}
                            />
                          </picture>
                        </div>
                        {reviewer.Reviewer_Video_URL && <button
                          onClick={() => {setPlay(true);setVideourl(reviewer.Reviewer_Video_URL);}}
                          className="video-wrap"
                        >
                          <i className="video-btn"></i>
                        </button>}
                        <div className="reviewer-info">
                          <span className="reviewer-name">
                            {reviewer.Reviewer_Name}
                          </span>
                          <span className="reviewer-type">
                            {reviewer.Reviewer_Role}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : null}
          </Col>
        </Row>
      </Container>
      {isPlay && videourl &&
        <PlayVideo
          isOpen={isPlay}
          autoplay
          videoId="V8ksSGxo6no"
          isCloseFunction={setPlay} 
          videourl={videourl}
        />
      }
    </section>
  );
};

export default Banner;
