import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import AreaguideCard from './AreaguideCard'
import "./AreaguidesListing.scss"

const AreaguidesListing = () => {
    const data = useStaticQuery(graphql`
      query GetGuides {
        glstrapi {
          areaguides {
            Title
            id
            Tile_Image {
              url
            }
            imagetransforms
            URL
          }
        }
      }
    `)

    const areaguides = data.glstrapi.areaguides
    
  return (
    <div className="areaguides-listing-wrapper">
      <div className="container">
        <div className="areaguides-listing">
          {areaguides.map(areaguide => (
            <AreaguideCard newsCard={areaguide} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AreaguidesListing