import React from "react"
import "./AwardCard.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"

const AwardCard = ({ award }) => {
  let processedImages = JSON.stringify({})
  if (award?.imagetransforms?.image_Transforms) {
    processedImages = award.imagetransforms.image_Transforms
  }
  const ImageRenderList = ({ image_url, award, processedImages }) => {
    return (
      <GetGGFXImage
          imagename={"awards.Image.tileimg"}
          imagesources={award.Image}
          fallbackalt={award.Nam}
          imagetransformresult={processedImages}
          id={award.id}
        />

    )
  }

  return (
    <div className="award-card">
      <div className="award-card-img zoom">
        {/* <img src={award.Image.url} alt="Image pic" /> */}
        <ImageRenderList processedImages={processedImages} image_url={award.Image && award.Image.url} award={award} />
        {/* <GetGGFXImage
          imagename={"awards.Image.tileimg"}
          imagesources={award.Image}
          fallbackalt={award.Nam}
          imagetransformresult={processedImages}
          id={award.id}
        /> */}
      </div>
      <div className="award-card-content">
        <p className="title">{award.Name}</p>
        <p className="year">{award.Select_Year.Year}</p>
      </div>
    </div>
  )
}

export default AwardCard
