import * as React from "react";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ContactDetails from "../ContactDetails/ContactDetails";
import MemberCard from "../MemberCard/MemberCard";
import StaffCard from "../MemberCard/StaffCard";
import ContactForms from "../ContactPageForm/InputData";
import BookViewing from "../forms/book-a-viewing";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Form.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import "../ContactPageForm/InputData.scss"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import {GetPropertyBooking, TeamDetail} from "../../queries/common_use_query"
import { Link } from "gatsby";
import { navigate } from '@reach/router';

// import {useLocation} from 'react-router-dom'; 

const Connect = (props) => {
  function stripTrailingSlash(str){
    if(str.charAt(str.length-1) == "/"){ str = str.substr(0, str.length - 1);}
    return str
 }
  const [windowWidth] = useWindowSize();
  var staffname = ""
  var staffemail = ""
  var teamdata = ""
  
  const location = useLocation();    
  const searchParams = parse(location.search) // => {init: "true"} 
  let pid = location?.state?.propertyId;
  if(!pid){
    pid = searchParams.pid && searchParams.pid.replace(/\/$/, ''); 
  } 
  if(searchParams.pid){
    navigate('/book-a-viewing/', { state: { propertyId: pid} });
  }
  

  //console.log('pid', pid)  
  const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
  var propertyurl= ""
  var propertyslug = ""
  var propertytext = ""
  var backurl = ""
  var neg_email = ""
  var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
  var department = prop_data &&  prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].department:"";
  var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";  
  var defaultUriStr = department === 'commercial' ? 'commercial-property' : 'property';
  if(prop_data && prop_data.properties.length > 0) {
      propertyurl =   searchtype=="sales"?`/${defaultUriStr}-for-sale/`:`/${defaultUriStr}-for-rent/`
      propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id;
      propertytext = department === 'commercial' ? (searchtype == "sales" ? "Commercial Property for sale" : "Commercial Property for rent") : (searchtype == "sales" ? "Property for sale" : "Property for rent");
      backurl =  searchtype=="sales"? `/${defaultUriStr}/for-sale/in-dubai/`:`/${defaultUriStr}/for-rent/in-dubai/`
  }
  
  neg_email = prop_data && prop_data.properties.length > 0 && prop_data.properties[0].negotiator_details ? prop_data.properties[0].negotiator_details.email : "";

  const {loading:loading, error:error, data:data} = TeamDetail(neg_email);
  if(data && data.teams && data.teams.length > 0) {
    staffname  = data.teams?data.teams[0].Name:""
    staffemail = data.teams?data.teams[0].Email:""
    teamdata   = data?.teams.length > 0 ? data.teams[0] :""
  }

  return (
    <>
      <section className="contact-wrap connect ">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-wrap">
                <div className="content-wrap">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>    
                    <li className="breadcrumb-item"><Link to={backurl}>{propertytext}</Link></li>                             
                    <Breadcrumb.Item>                      
                      Book a viewing
                    </Breadcrumb.Item>
                    <li className="breadcrumb-item active"><Link to={`${propertyurl}${prop_data?.properties[0]?.slug}-${pid}/`}>{prop_data?.properties[0]?.address?.address}</Link></li>

                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
              <Col className="contact-title">
                <h1>{staffname ? "Contact "+staffname : props.title}</h1>
                <p>Are you interested in this property? Fill in the form to know more by booking a viewing.</p>
              </Col>
          </Row>
          <Row>
          {prop_data && prop_data.properties.length > 0 &&
            <Col lg={8} className="form-field-wrapper">            
            <BookViewing 
            property_url={location.origin+propertyurl+propertyslug} 
            page_url={location.origin+propertyurl+propertyslug} 
            property_address={displayaddress} 
            property_id={prop_data.properties[0].id} 
            property_title={displayaddress} 
            price={prop_data.properties[0].price}
             type={prop_data.properties[0].title} 
             telephone= ""  
             property_img={(prop_data.properties[0].images && prop_data.properties[0].images.length > 0) && prop_data.properties[0].images[0].url} 
             property_bedrooms={prop_data.properties[0].bedroom} property_bathrooms={prop_data.properties[0].bathroom}/>            
            </Col>
            }
            <Col lg={4} className="card-field-wrapper">
              {staffemail ?
              <StaffCard staffdata={teamdata} />
              :
              <MemberCard />
              }
              <ContactDetails />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Connect;
