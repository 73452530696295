import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import "./CurrrentOpenings.scss"
import { useWindowSize } from "../../hooks/window-size"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { graphql, useStaticQuery } from "gatsby"

// const careers = [
//   {
//     post: "Sales Account Manager",
//     address: "Dubai, Dubai, United Arab Emirates",
//     department: "Sales & Marketing",
//     detailLink: "/",
//   },
//   {
//     post: "Estate Agent Sales Negotiator",
//     address: "Dubai, Dubai, United Arab Emirates",
//     department: "Sales",
//     detailLink: "/",
//   },
//   {
//     post: "Chartered Senior Quantity Surveyor",
//     address: "Dubai, Dubai, United Arab Emirates",
//     department: "Sales & Lettings",
//     detailLink: "/",
//   },
//   {
//     post: "Business Development Manager - Automation",
//     address: "Dubai, Dubai, United Arab Emirates",
//     department: "HR",
//     detailLink: "/",
//   },
// ];

const CurrentOpenings = () => {
  const [windowWidth] = useWindowSize()

  const data = useStaticQuery(graphql`
    query GetCareers {
      glstrapi {
        careers {
          Department
          Location
          Job_Opening
          URL
        }
      }
    }
  `)

  const careers = data.glstrapi.careers

  return (
    <section className="current-openings" id="career-open-positions">
      <Container>
        <Row>
          <Col>
            <h3>Current Open Positions</h3>
            <p>
              Here are some of the recent job vacancies at McCone Properties.
            </p>
            <div className="posts-block">
              {careers.map(career => (
                <div className="post-wrap">
                  <span className="post-name">
                    {windowWidth < 768
                      ? `${career.Job_Opening.slice(0, 16)}...`
                      : career.Job_Opening}
                  </span>
                  {windowWidth > 1199 ? (
                    <>
                      <span className="post-address">
                        <address>{career.Location}</address>
                      </span>
                      <span className="post-department">
                        {career.Department}
                      </span>
                    </>
                  ) : null}
                  <span className="post-details">
                    <Link to={`/careers/${career.URL}/`}>
                      view details <i className="icon-right"></i>
                    </Link>
                  </span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CurrentOpenings
