import React from "react"
import { Link } from "gatsby"
import "./SubMenuListing.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import { Row } from "react-bootstrap"

const AreaguideCard = props => {
  const menuItem = props.newsCard
  const menuLink = menuItem.Menu_Link

  return (
    <div className="areaguide-card zoom">
      {menuItem.Tile_Image && 
      <div className="image">
        <GenerateLink link={menuLink?.Primary_URL} secondarylink={menuLink?.Secondary_URL} class="d-inline-block zoom">
          <GetGatsbyImage
            image={menuItem.Tile_Image}
            fallbackalt={menuLink?.Label}
          />
        </GenerateLink>
      </div>}
      <div className="areaguides-info">
        <div className="areaguides-title">
          <p>
            <GenerateLink 
              class="title" 
              link={menuLink?.Primary_URL}
              secondarylink={menuLink?.Secondary_URL}
            >
              {menuLink?.Label}
            </GenerateLink>
          </p>

          <GenerateLink
            className="link"
            link={menuLink?.Primary_URL}
            secondarylink={menuLink?.Secondary_URL}
            class="btn-newscard"
          >
            View Details<i className="icon-right"></i>
          </GenerateLink>
        </div>
      </div>
    </div>
  )
}

export default AreaguideCard
