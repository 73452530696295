import React from "react";
import { Link } from "gatsby";
import Img01 from "../../images/propertyDetails/dealer01.png";
import Img02 from "../../images/newsdetails/client02.png";
import "./MemberCard.scss";
import { graphql, useStaticQuery } from "gatsby";

const MemberCard = ({staff, waMsg}) => {

  const data = useStaticQuery(graphql`
    query GetSpecificContactWithIDCareer {
      glstrapi {
        team(id: "629d9947dcd59b153b9b9eb3") {
          Designation
          URL
          Email
          id
          Phone
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  let staffMember = data.glstrapi.team;

  if(!staffMember) {
    return null;
  }

  return (
    <>
      <div className="dealer-info ">
        <div className="dealer-wrap">
          <div className="img-wrap zoom">
            <Link to={`/meet-the-team/${staffMember.URL}/`}>
            <picture>
              <source media="(min-width: 768px)" srcSet={staffMember.Image.url} />
              <img src={staffMember.Image.url} alt="contact" className="show-img"/>
              <img src={staffMember.Image.url} alt="contact" className="hide-img d-none" />
            </picture>
            </Link>
          </div>
          <div className="dealer-right top-right d-md-none">
          <Link to={`/meet-the-team/${staffMember.URL}/`}>
            <div className="dealer-name">{staffMember.Name}</div>
          </Link>
            <div className="dealer-position">{staffMember.Designation}</div>
            <div className="dealer-contact">
              <span className="title">Languages:</span>{staffMember.languages}
            </div>
          </div>

          <div className="dealer-right bottom-right d-none d-md-flex">
          <Link to={`/meet-the-team/${staffMember.URL}/`}>
            <div className="dealer-name">{staffMember.Name}</div>
          </Link>
            <div className="dealer-position">{staffMember.Designation}</div>
            <Link href={`tel:${staffMember.Phone}`} className="contact">
              <i className="icon-call"></i>
              {staffMember.Phone}
            </Link>
            <a href={`https://wa.me/${staffMember.Phone.split(" ").join("")}?text=${waMsg ? waMsg : ""}`} target="_blank" className="contact">
              <i className="icon-whatsapp"></i>
              Click to WhatsApp
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberCard;
