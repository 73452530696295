import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../PlayVideo/PlayVideo";
import Img01 from "../../images/estate01.png";
import Img02 from "../../images/estate02.png";
import Img03 from "../../images/estate03.png";
import { Link } from "gatsby";
import "./Estate.scss";
import parse from 'html-react-parser';
import GenerateLink from "../common/site/GetLink"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getSrc } from "gatsby-plugin-image"
import GetGatsbyImage from "../common/site/get-gatsby-image";
import { AnimationOnScroll } from "react-animation-on-scroll";
import GetGGFXImage from "../common/site/get-ggfx-image"

const Customer = (props) => {
  const [isPlay, setPlay] = useState(false); 
  // const image = getImage(props.image.url_sharp)

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = props.imagetransforms.Tile_Image_Transforms
  }

  return (
    <React.Fragment>
      <section className="estate">
        <Container>
          <Row>
            <Col md={12} lg={6} className="get-zoom order-lg-2">
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInRight"
              >
                <div className="estate-img ">
                  <picture className="img-zoom">
                    <GetGatsbyImage
                      image={props.image}
                      fallbackalt={props.heading}
                    />
                  </picture>
                  {props.videoURL && (
                    <button
                      className="video-wrap"
                      onClick={e => {
                        setPlay(true)
                      }}
                    >
                      <i className="video-btn"></i>
                    </button>
                  )}
                </div>
              </AnimationOnScroll>
            </Col>
            <Col md={12} lg={6} className="estate-img-wrap">
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInLeft"
              >
                <div className="estate-info">
                  <h2 className="sm-title">{props.heading}</h2>
                  {parse(props.content)}

                  {props.ctas && (
                    <div className="btn-wrap">
                      {props.ctas.map((item, index) => (
                        <GenerateLink
                          link={item.CTA_Link.Primary_URL}
                          class={index === 0 ? "btn" : "btn btn-secondary"}
                        >
                          <span>{item.CTA_Label}</span>
                          <i className="icon-right"></i>
                        </GenerateLink>
                      ))}
                    </div>
                  )}
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </section>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          videoId="V8ksSGxo6no"
          isCloseFunction={setPlay}
          videourl={props.videolink}
        />
      )}
    </React.Fragment>
  )
};
export default Customer;
