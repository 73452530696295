import React from "react";
import { Link } from "gatsby";
import "./NewsCard.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import GenerateLink from "../common/site/GetLink";
import { useWindowSize } from "../../hooks/window-size"


const NewsCard = (props) => {
  const NewsItem = props.newsCard;

  let processedImages = JSON.stringify({});
  if (NewsItem?.imagetransforms?.Tile_Image_Transforms) {
   processedImages = NewsItem.imagetransforms.Tile_Image_Transforms;
  }

  const ggfxImageName = props.bigCard ? "news-insights.Tile_Image.bigtileimg" : "news-insights.Tile_Image.tileimg"


  const [windowWidth] = useWindowSize()

  return (
    <React.Fragment>
      <div className={props.bigCard ? "news-card  zoom " :"news-card news-card-small zoom" }>
        <div className="properties-wrapper">
          <Link to={`/about/latest-property-news-and-video/${NewsItem.URL}/`} className="d-inline-block zoom">
          <GetGGFXImage imagename={ggfxImageName} imagesources={NewsItem.Tile_Image} fallbackalt={NewsItem.Title} imagetransformresult={processedImages} id={NewsItem.id}/>                  
          </Link>
          {windowWidth < 992 && NewsItem.Select_Category ? (
            <span className="sm-text">{NewsItem.Select_Category.Name}</span>
          ) : null}
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <div className={props.bigCard ? "properties-heading  properties-heading-big-card" : "properties-heading "}>
            {NewsItem.Select_Category ? (
            <p className="category">{NewsItem.Select_Category.Name}</p>
          ) : null}
              <h2>
                <Link to={`/about/latest-property-news-and-video/${NewsItem.URL}/`}>{NewsItem.Title}</Link>
              </h2>

              {/* <GenerateLink link={""} class="btn-newscard">View Guide<i className="icon-right"></i></GenerateLink> */}

              {NewsItem.Reading_Time ? <div className="time">
             
             <i className="icon-clock"></i>
             <span className="sm-text">{NewsItem.Reading_Time}</span>
           </div> : null }
            </div>
          </div>
          <div className={props.bigCard ? "content content-big-card" : "content"}>
            {NewsItem.Description ? <p className="description">{NewsItem.Description}</p> : null}
            <Link to={`/about/latest-property-news-and-video/${NewsItem.URL}/`} className="description-link">
                  <span className="read-more">
                    Continue reading
                    <i className="right-more"></i>
                  </span>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsCard;
