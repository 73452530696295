import React from 'react'
import LoadExternalScript from '../utils/load-external-script'
import "./SocialWall.scss"

const SocialWall = () => {
  return (
    <div className="container social-wall">
        <LoadExternalScript src="https://apps.elfsight.com/p/platform.js" defer="true"/>
        <div class="elfsight-app-4e905fec-8359-4bc3-bff9-27569e810bb6"></div>
    </div>
  )
}

export default SocialWall