import React from "react"
import { Link } from "gatsby"
import "./AreaguidesListing.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import { Row } from "react-bootstrap"

const AreaguideCard = props => {
  const areaguideItem = props.newsCard

  let processedImages = JSON.stringify({})
  if (areaguideItem?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = areaguideItem.imagetransforms.Tile_Image_Transforms
  }

  return (
    <div className="areaguide-card zoom">
      <div className="image">
        <Link to={`/about/living-in-dubai/${areaguideItem.URL}/`} className="d-inline-block zoom">
          <GetGGFXImage
            imagename={"areaguides.Tile_Image.tileimg"}
            imagesources={areaguideItem.Tile_Image}
            fallbackalt={areaguideItem.Title}
            imagetransformresult={processedImages}
            id={areaguideItem.id}
          />
        </Link>
        {areaguideItem.Select_Category ? (
          <span className="sm-text">
            {areaguideItem.Select_Category.Name}
          </span>
        ) : null}
      </div>
      <div className="areaguides-info">
        <div className="areaguides-title">
          <p>
            <Link className="title" to={`/about/living-in-dubai/${areaguideItem.URL}/`}>
              {areaguideItem.Title}
            </Link>
          </p>

          <Link
            className="link"
            to={`/about/living-in-dubai/${areaguideItem.URL}/`}
            class="btn-newscard"
          >
            View Guide<i className="icon-right"></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AreaguideCard
