import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Select from 'react-select'
import Map from "../../images/map.png";
import { useWindowSize } from "../../hooks/window-size";
import "./PropertySale.scss";
import CurrConvertLayout from "../currency-converter/CurrConvertLayout"

const PropertyFeatures = (props) => {
  const [windowWidth] = useWindowSize();
  const [propertyList, setBoxContent] = useState([]);
  let currncy_values = [
    {value:'AED', label:'AED'},
    {value:'GBP', label:'GBP'},
    {value:'EUR', label:'EUR'},
    {value:'USD', label:'USD'},
    {value:'RUB', label:'RUB'},
    {value:'INR', label:'INR'}
  ]
  const [mycurr, setmycurr] = useState(currncy_values[0]);
  

  const searchForOptions = ["Most Recent", "Highest Price", "Lowest Price"];
  const department = [
    { value: 'Most Recent', label: 'Most Recent ' },
    { value: 'Highest Price', label: 'Highest Price' },
    { value: 'Lowest Price', label: 'Lowest Price' },
  ]
  const [searchFor, setSearchFor] = useState("Most Recent");
  const searchTypeDropdownHandler = (e) => {
    setSearchFor(e);
    props.sort(e);
  };
  const [icon,SetIcon] = useState(false)
  const showIcon = ()=>{
    SetIcon(!icon)
  }

  const handleCurrConvert = (childData) =>{
    //setState({mycurr: childData})
    setmycurr(childData);
    props.setpriceChange(childData)
    //const [mycurr, setmycurr] = useState(childData);
    //const [selectedProperties, setSelectedProperties] = useState(props.properties)
  }

  return (
    <React.Fragment>
      <section className="property-sale off-plan-page">
        <Container>

                <h1 className="heading">Latest Dubai Off Plan Projects</h1>
                <div className="offplan-info-wrap">

                    <div className="content-wrap offplan-content-wrap">
                      <span className="number">{props.count} </span>
                      {windowWidth < 992 ?  <span className="sm-title ">properties</span> :
                      <span className="sm-title ">
                        properties found
                      </span>}
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="dropdowns-wrap offplan-sort">
                    <Select options={department} placeholder="Most Recent" className="react-select"  classNamePrefix="react-select" onChange={e => props.sort(e.value)} isSearchable={false}/>
                      {/* <Dropdown
                        onSelect={searchTypeDropdownHandler}
                        className="select-option" >
                        <Dropdown.Toggle id="dropdown-basic">
                          {searchFor}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {searchForOptions.map((option, index) => (
                            <Dropdown.Item key={index} eventKey={option}>
                              {option}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                    <div className="vertical-divider divider-currency"></div>
                    <div className="search-select-box search-select-box-dropdown">
                      <CurrConvertLayout parentCallback = {handleCurrConvert} outterClass="currency_select" currncy_values={currncy_values} selectClass={"react-select"} classNamePrefix="react-select"/>
                    </div>
                    {/* <Link href="#" className="icon-wrap">
                      <i onClick={showIcon}  className={icon ? "icon-fill" : "heart-light"}></i>
                      <span className="d-md-none"> Save</span>
                      <span className="d-none d-md-block">Save Search</span>
                    </Link> */}

                  {/* <div className="map d-none d-md-flex">
                    <div className="map-info">
                      <img src={Map} />
                      <i className="google-map"></i>
                    </div>
                    <div className="map-info text">
                      <Link className="map-text d-none d-xl-block" href="#">
                        View on
                      </Link>
                      <Link href="#">Map</Link>
                    </div>
                  </div> */}
                </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PropertyFeatures;
