import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import "./MortgageRepayments.scss";
import mortgageLogo from "../../images/propertyDetails/mortgage-logo.png";
import RentalYieldForm from "../MortgageForm/RentalYieldForm";
import RentalCalc from "../calculators/rentalyieldcalc/index"
import { useLocation } from "@reach/router"

const RentalYield = (props) => {
  const [years, setYears] = useState("25");
  const yearSelect = (e) => {
    setYears(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  var { pathname } = useLocation();
  var displayHeading = pathname?.includes("rental-yield-calculator") && "Fill out the fields below to calculate rental yield";

  return (
    <React.Fragment>
      <div className="mortgage-repayments">
        {/* <h3>Rental Yield Calculator</h3> */}
        {/* <p>Calculate your payments with our rental yield calculator.</p> */}
        {displayHeading && <h2>{displayHeading}</h2>}
	    	<RentalYieldForm price={props.price}/>
            {/* <RentalCalc/> */}
      </div>
    </React.Fragment>
  );
};

export default RentalYield;
