import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Dropdown } from "react-bootstrap";
import mortgageLogo from "../../images/propertyDetails/mortgage-logo.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./MortgageForm.scss";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import {calculateMonthlyPayment} from "../calculators/calculators"
import $ from "jquery"

const Form = (props) => {

  const filterNumber = str => {
    if (!str) return 0
    str = str.toString()
    return parseInt(str.replace(/[^\d.]/g, ""), 10)
  }

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AED",
  })

  const [monthlyRepayment, setMonthlyPayment] = useState(13207.03)

  const [interestRate, setinterestRate] = useState(4.8)
  const handleChangeInterestRate = e => {
    setinterestRate(e.target.value)
  }

  const [downPayment, setdownPayment] = useState("600000")
  const [downPaymentComma, setdownPaymentComma] = useState("600,000")

  const handleChangeDownPayment = value => {

    if(value.length == 0) {
      setdownPaymentComma(addCommas("0"))
    } else {
      setdownPaymentComma(addCommas(value))
    }

    setdownPayment(value)
  }

  const [loanValue, setloanValue] = useState("3000000")
  const [loanValueComma, setloanValueComma] = useState("3,000,000")

  const handleChangeLoanValue = value => {
    if(value.length == 0) {
      setloanValueComma(addCommas("0"))
    } else {
      setloanValueComma(addCommas(value))
    }

    setloanValue(value)
  }

  const [duration, setduration] = useState(25)
  const handleChangeduration = value => {
    setduration(value)
  }

  const handleSubmit = () => {
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(loanValue)),
        parseFloat(interestRate),
        parseInt(filterNumber(downPayment)),
        duration
      )
    )
  }

  useEffect(() => {
    handleSubmit()
    //console.log("testing122", loanValue, interestRate, duration, downPayment)

    // var settotalprice = $('.totalprice input').val();
    // settotalprice.toLocaleString();
    // $('.totalprice input').val(settotalprice);

  }, [loanValue, interestRate, duration, downPayment])

  useEffect(() => {
    if (props?.property) {
      setloanValue(props?.property?.price)
    }
    handleSubmit()
 
    
  }, [])

  // useEffect(() => {

  // var el = document.querySelector('.totalprice input');

  // el.addEventListener('keyup', function (event) {
  //   if (event.which >= 37 && event.which <= 40) return;
  //   this.value = this.value.toLocaleString();
  // });
  // });

  const addCommas = (value) => {
    return parseInt(value).toLocaleString()
  }

  return (
    <>
        <form onSubmit={handleSubmit}>
          <div className="payment-card">
            <div className="card-left">
              <div className="input-wrap totalprice">
                <label>total price (AED)</label>
                <input
                  placeholder="4,550,000"
                  value={loanValueComma}
                  onChange={e => {
                    const val = e.target.value.split(",").join("")
                    // handleChangeLoanValue(val)
                    const re = /^[0-9]*$/
                    
                    if (re.test(val)) {
                      handleChangeLoanValue(val)
                    }
                  }}
                />
              </div>
              <div className="input-wrap">
                <label>loan period (years)</label>
                <Dropdown
                  onSelect={handleChangeduration}
                  className="select-option"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    {duration}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="25">25</Dropdown.Item>
                    <Dropdown.Item eventKey="30">30</Dropdown.Item>
                    <Dropdown.Item eventKey="35">35</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="input-wrap">
                <label>Down Payment (AED)</label>
                <input
                  placeholder="450,000"
                  onChange={e => {
                    const val = e.target.value.split(",").join("")
                    
                    // handleChangeLoanValue(val)
                    const re = /^[0-9]*$/
                    
                    if (re.test(val)) {
                      handleChangeDownPayment(val)
                    }
                  }}
                  value={downPaymentComma}
                />
              </div>
              <div className="input-wrap">
                <label>Interest Rate (%)</label>
                <input
                  type="number"
                  placeholder="4.8"
                  onChange={handleChangeInterestRate}
                  value={interestRate}
                />
              </div>
              <div className="mortgage-detail">
                <div className="img-wrap">
                  <img src={mortgageLogo} alt="logo" />
                </div>
                <span className="text-wrap">
                  <span className="md d-md-none">Powered by </span>
                  <span className="dd d-none d-md-inline-block">
                    In partnership with{" "}
                  </span>{" "}
                  Mena Mortgage Services
                </span>
              </div>
            </div>
            <div className="card-right">
              <span className="payment-text">monthly repayment</span>
              <span className="price-wrap">{formatter.format(monthlyRepayment)}</span>
              <span className="cta-text">Speak with our mortgage broker</span>
              <div className="btn-wrap">
                <Link to="/book-an-appointment/" className="btn">
                  Book an appointment
                  <i className="icon-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </form>
    </>
  )
};

export default Form;
