import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import axios from "axios"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import PropertyCard from "../PropertyCard/PropertyCard"
import { FeaturedProperties } from "../../queries/common_use_query"
// import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFeatures.scss"

import {
  UserObjectStoreProvider,
  useAuthState,
  getUserobjectData,
} from "@starberry/myaccount-website-utils"

const PropertyFeaturesComponent = props => {
  const listingIDs = props?.module?.Listing_IDs
  const CountListIds = listingIDs.split(",")

  if (!listingIDs) return null

  const {
    loading: prop_loading_sale,
    error: prop_error_sale,
    data: prop_data,
  } = FeaturedProperties(listingIDs.split(/[ ,]+/), ["for sale", "to let"])

  const heading =
    props?.type === "GLSTRAPI_ComponentComponentsSelectFeaturedProperties"
      ? "Featured Properties"
      : props.type === "GLSTRAPI_ComponentComponentsSelectReserevedProperties"
      ? "Reserved Properties"
      : ""

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: CountListIds.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: CountListIds.length > 3 ? true : false,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      {prop_data && prop_data.properties.length > 0 ? (
        <section className="property-features">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="heading">{heading}</h2>
                <Slider {...settings} key="firstone">
                  {prop_data &&
                    prop_data.properties.map((property, index) => {
                      let processedImages = JSON.stringify({})
                      if (property?.imagetransforms?.images_Transforms) {
                        processedImages =
                          property.imagetransforms.images_Transforms
                      }
                      var uriStr = '';
                      if (property.department === 'residential') {
                          uriStr = property.search_type === "sales" ? '/property-for-sale/' : '/property-for-rent/';
                      } else if (property.department === 'commercial') {
                          uriStr = property.search_type === "sales" ? '/commercial-property-for-sale/' : '/commercial-property-for-rent/';
                      }
                      

                      return (
                        <PropertyCard
                          key={index}
                          property={property}
                          price={property?.price && "AED " + property.price.toLocaleString()}
                          PropertyPrice={property?.price.toLocaleString()}
                          propertyid={property.id}
                          location={property?.address?.address}
                          description={property.description}
                          bedroomsCount={property.bedroom}
                          bathroomsCount={property.bathroom}
                          propertyImages={property.images}
                          propertyDetailsLink={`${uriStr}${property.slug}-${property.id}/`}
                          status={property.status}
                          priceQualifier={property.price_qualifier}
                          propertySearchType={property.search_type}
                          sqft={property.size}
                          card_type="similar_property"
                          processedImages={processedImages}
                          userObjects={props.userObjects}
                        />
                      )
                    })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

const SelectPropertyFeatures = props => {
  const [userObjects, setUserObjects] = useState({})
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData()
          setUserObjects(userObjects.data.data)
        } catch (e) {
          console.log("e:", e)
        }
      }
      getUserObjects()
    }
  }, [])

  return (
    <UserObjectStoreProvider>
      <PropertyFeaturesComponent {...props} userObjects={userObjects} />
    </UserObjectStoreProvider>
  )
}

export default SelectPropertyFeatures
