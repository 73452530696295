import { Link } from 'gatsby'
import React from 'react'
import GenerateLink from "../common/site/GetLink"

const PackageCard = ({item}) => {
    if (typeof item === 'undefined') return null

  return (
    <div className="card">
      <div className="card-content">
        <h6 className="card-name">{item.Title}</h6>
        <p className="card-price">{item.Price}</p>
        <p className="card-description">{item.Description}</p>
        <div className="card-cta">
          <Link to='#' className="btn">
            Enquire now
            <i className="icon-right"></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PackageCard