import * as React from "react";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ContactDetails from "../ContactDetails/ContactDetails";
import MemberCard from "../MemberCard/MemberCard";
import StaffCard from "../MemberCard/StaffCard";
import ContactForms from "../ContactPageForm/InputData";
import ContactForm from "../forms/list-my-property";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Form.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import parse from "html-react-parser"
import "../ContactPageForm/InputData.scss"

const Connect = (props) => {
  const [windowWidth] = useWindowSize();
  var staffname = ""
  var staffemail = ""
  var teamdata = ""
  if(props.data && props.data.teams && props.data.teams.length > 0) {
      staffname  = props.data.teams?props.data.teams[0].Name:""
      staffemail = props.data.teams?props.data.teams[0].Email:""
      teamdata   = props.data?.teams.length > 0 ? props.data.teams[0] :""
  }

  const waMsg = "Hi there, I came across McCone Properties’ website and wanted to book a valuation/list my property"

  return (
    <>
      <section className="contact-wrap connect ">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-wrap">
                <div className="content-wrap">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>                   
                    <Breadcrumb.Item active>                      
                      Book a Valuation
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
              <Col className="contact-title">
                <h1>{staffname ? "Contact "+staffname : props.title}</h1>
                {parse(props.content)}                
              </Col>
          </Row>
          <Row>
            <Col lg={8} className="form-field-wrapper">
                <ContactForm to_email_id={props.staffemail}/>
            </Col>
            <Col lg={4} className="card-field-wrapper">
              {staffemail ?
              <StaffCard staffdata={teamdata} waMsg={waMsg}/>
              :
              <MemberCard waMsg={waMsg}/>
              }
              <ContactDetails />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Connect;
