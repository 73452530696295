import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import AeroDesk from "../../images/landing/aero.jpg"
import Car from "../../images/landing/car1800.jpg"
import CarTab from "../../images/landing/car1800.jpg"
import CarDesk from "../../images/landing/car1800.jpg"
import propertyTab02 from "../../images/landing/1802.jpg"
import DownTownDesk from "../../images/landing/1803.png"
import Location01 from "../../images/landing/place01.png"
import Location02 from "../../images/landing/place02.png"
import Location03 from "../../images/landing/place03.png"
import Location04 from "../../images/landing/place04.png"
import Location05 from "../../images/landing/place05.jpg"
import { useWindowSize } from "../../hooks/window-size"

import NewsCard from "../News/NewsCard"

import "./AwardsListing.scss"
import { graphql, useStaticQuery } from "gatsby"
import AwardCard from "./AwardCard"
const AwardsListing = () => {
  const data = useStaticQuery(graphql`
    query GetAwards {
      glstrapi {
        awardsYears {
          Year
          id
        }
        awards(sort: "date:DESC") {
          Name
          Image {
            url
          }
          Select_Year {
            Year
            id
          }
          id
          imagetransforms
        }
      }
    }
  `)

  const awardsYears = data.glstrapi.awardsYears
  awardsYears.sort((a, b) => b.Year - a.Year)
  
  const awards = data.glstrapi.awards
  awards.sort((a, b) => b.Select_Year.Year - a.Select_Year.Year)
  
  const [windowWidth] = useWindowSize()

  const [filterClose, setFilterclose] = useState(false)
  const filterBtn = () => {
    setFilterclose(!filterClose)
  }

  const [filter, setFilter] = useState("all")
  let [awardsToShow, setAwardsToShow] = useState([])

  useEffect(() => {
    setAwardsToShow(awards)
  }, [])

  useEffect(() => {

    if (filter === "all") {
      setAwardsToShow(awards)
    } else {
      const filteredAwards = awards.filter(award => {
        return award.Select_Year.Year === filter
      })
      setAwardsToShow(filteredAwards)
    }
  }, [filter])

  return (
    <>
      <section className="category-wrap">
        <Container>
          <Row className="RowMerge">
            <Col className="RowMerge">
              <button
                type="button"
                className="category-btn dropdown-toggle btn btn-primary"
                onClick={filterBtn}
              >
                {filter == "all" ? "all categories" : filter}

                <i className="drop-arrow"></i>
              </button>
              <nav className={`nav-wrap ${filterClose ? "nav-menu-open" : ""}`}>
                <ul className="nav-menu">
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "all" ? "filter-link active" : "filter-link"
                      }
                      active={filter === "all"}
                      onClick={() => {
                        setFilter("all")
                        setFilterclose(false)
                      }}
                    >
                      all years
                    </button>
                  </li>
                  {awardsYears.map(year => (
                    <>
                      <li>
                        <button
                          type="button"
                          className={
                            filter === year.Year
                              ? "filter-link active"
                              : "filter-link"
                          }
                          active={filter === year.Year}
                          onClick={() => {
                            setFilter(year.Year)
                            setFilterclose(false)
                          }}
                        >
                          {year.Year}
                        </button>
                      </li>
                    </>
                  ))}
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="news-reads">
        <div className="news-reads-wrap">
          <Container>
            <div className="awards-cards">
            {awardsToShow.map(award => (
              <AwardCard award={award}/>
            ))}
            </div>
            

              {/* {windowWidth < 767 ? (
                <>
                  {projects.slice(1, 3).map(property =>
                    property.filtered === true ? (
                      <Col className="p-0 col-12 col-md-6 col-lg-4  medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}

              {windowWidth >= 768 && windowWidth <= 991 ? (
                <>
                  {projects.slice(1, 5).map(property =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6 col-md-6 col-lg-4 name789  medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}

              {windowWidth >= 992 ? (
                <>
                  {projects.slice(1, 4).map(property =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6  col-lg-4   medium-card ">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
            </Row>

            <Row className="m-0 row-cols-5 last-wrap">
              {windowWidth <= 1699 ? (
                <>
                  {projects.slice(5, 9).map(property =>
                    property.filtered === true ? (
                      <Col className="p-0 col-6 col-md-3 02 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null}
              {windowWidth > 1700 ? (
                <>
                  {projects.slice(5).map(property =>
                    property.filtered === true ? (
                      <Col className=" p-0 col-6 col-md-3 03 small-card">
                        <NewsCard key={property.id} newsCard={property} />
                      </Col>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : null} */}
          </Container>
        </div>
      </section>
    </>
  )
}
export default AwardsListing
