import React from "react";
import { Link } from "gatsby";
import Img01 from "../../images/propertyDetails/dealer01.png";
import Img02 from "../../images/newsdetails/client02.png";
import "./MemberCard.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";

const MemberCard = (props) => {
    var teamdata = props.staffdata
    let processedImages = JSON.stringify({});
    if (teamdata?.imagetransforms?.Image_Transforms) {
     processedImages = teamdata.imagetransforms.Image_Transforms;
    }

  return (
    <>
      <div className="dealer-info ">
        <div className="dealer-wrap">
          <div className="img-wrap zoom">
            <picture>
            {/* <img src={teamdata?.Image?.url} alt={teamdata.Name +" "+ teamdata.Designation}  /> */}
            <Link to={`/meet-the-team/${teamdata.URL}/`}>
              <GetGGFXImage imagename={"teams.Image.sidebarimg"} imagesources={teamdata.Image} fallbackalt={teamdata.Name +" "+ teamdata.Designation} imagetransformresult={processedImages} id={teamdata.id}/>                                
            </Link>
            </picture>
          </div>
          <div className="dealer-right top-right d-md-none">
          <Link to={`/meet-the-team/${teamdata.URL}/`}>
            <div className="dealer-name">{teamdata.Name}</div>
          </Link>
            
            <div className="dealer-position">{teamdata.Designation}</div>
            <div className="dealer-contact">
              <span className="title">Languages:</span>{teamdata.languages}
            </div>
          </div>

          <div className="dealer-right bottom-right d-none d-md-flex">
             <Link to={`/meet-the-team/${teamdata.URL}/`}>
              <div className="dealer-name">{teamdata.Name}</div>
            </Link>
            <div className="dealer-position">{teamdata.Designation}</div>
            <a href={`tel:${teamdata.Phone}`} className="contact">
                <i className="icon-call"></i>{teamdata.Phone}
            </a>
            <a href={`https://wa.me/+971585138750?text=${props.waMsg ? props.waMsg : ""}`} target="_blank" className="contact">
              <i className="icon-whatsapp"></i>
              Click to WhatsApp
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberCard;
