import React, { useState } from "react";
import { Link } from "gatsby";
import { Form, Button, Dropdown } from "react-bootstrap";
import Select from 'react-select'
import "./ContactForm.scss";
import OffPlanContact from "../forms/offplan-contact";
const OffPlanContactForm = () => {



  const department = [
    { value: 'Sales ', label: 'Sales ' },
    { value: 'Product', label: 'Product' },
    { value: 'Sales', label: 'Sales' },
  ]
  const value = [
    { value: 'I’m looking to buy', label: 'I’m looking to buy' },
    { value: 'I’m looking to sell', label: 'I’m looking to sell' },
    { value: 'I’m looking to buy', label: 'I’m looking to buy' }
  ]
  
  
  
  return (
    <>
      <div className="form-wraps">        
        <OffPlanContact/>
      </div>
    </>
  );
};
export default OffPlanContactForm;
