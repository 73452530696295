import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AeroDesk from "../../images/landing/aero.jpg";
import Car from "../../images/landing/car1800.jpg";
import CarTab from "../../images/landing/car1800.jpg";
import CarDesk from "../../images/landing/car1800.jpg";
import propertyTab02 from "../../images/landing/1802.jpg";
import DownTownDesk from "../../images/landing/1803.png";
import Location01 from "../../images/landing/place01.png";
import Location02 from "../../images/landing/place02.png";
import Location03 from "../../images/landing/place03.png";
import Location04 from "../../images/landing/place04.png";
import Location05 from "../../images/landing/place05.jpg";
import { useWindowSize } from "../../hooks/window-size";
import parse from 'html-react-parser';

import AreaguideCard from "../AreaguideCard/AreaguideCard";

import "./Reasons.scss"
import GenerateLink from "../common/site/GetLink";
import Slider from "react-slick";
import ReasonCard from "../ReasonCard/ReasonCard";
import { AnimationOnScroll } from "react-animation-on-scroll";

const SelectReasons = ({module}) => {
  const [windowWidth] = useWindowSize();

  const settings =  {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    
      <section className="select-reasons">
        <div className="select-reasons-wrap">
          <Container>
            <div className="intro">
              <div className="intro-content">
                <h2 className="heading">{module.Module_Title}</h2>
                <p>{module.Module_Description}</p>
              </div>
            </div>

            <Row className="areaguide-cards">
              <Slider className="areaguide-slider" {...settings}>
                {module.Tile_Image_Items.map(item => (
                  <Col className="small-card">
                    <ReasonCard key={item.id} card={item} />
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </section>
  )
};
export default SelectReasons;
