import React, {useState} from "react"
import { useWindowSize } from "../../hooks/window-size"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import ContactDetails from "../ContactDetails/ContactDetails"
import MemberCard from "../MemberCard/MemberCardContactForm"
import StaffCard from "../MemberCard/StaffCard"
import ContactForms from "../ContactPageForm/InputData"
import ContactForm from "../forms/contact"
import StaffContactForm from "../forms/staff-contact"
import { Helmet } from "react-helmet"
//import BrandLogo from "../../images/logo.svg";
import darkBrandLogo02 from "../../images/logo-dark-tab.svg";
import FeedBackModal from "../FeedBackModal/FeedBackModal"


import { AnimationOnScroll } from "react-animation-on-scroll"
import "./Form.scss"
import { propTypes } from "react-bootstrap/esm/Image"
import parse from "html-react-parser"
import "../ContactPageForm/InputData.scss"

const Connect = props => {
  const [windowWidth] = useWindowSize()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  const baseUrl = process.env.GATSBY_SITE_URL;
  var staffname = ""
  var staffemail = ""
  var teamdata = ""
  if (props.data && props.data.teams && props.data.teams.length > 0) {
    staffname = props.data.teams ? props.data.teams[0].Name : ""
    staffemail = props.data.teams ? props.data.teams[0].Email : ""
    teamdata = props.data?.teams.length > 0 ? props.data.teams[0] : ""
  }
  //console.log("npm uninstall @netlify/plugin-gatsby", staffemail)
  const waMsg = `Hi there, I came across McCone Properties’ website and would like further information`
  //console.log("props",props)
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`{
          
        "@context": "http://schema.org",
        "@type": "Product",
        "name" : "${props?.page?.Meta_Title}",
        "description" : "${props?.page?.Meta_Description}",
        "brand" : {
          "@type" : "Brand",
          "name" : "Mccone Properties",
          "image" : "${darkBrandLogo02}"
      },
    "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "212",
            "bestRating": "5"
        }     
    }`}
        </script> 
        <script type="application/ld+json">{`{
           "@context": "https://schema.org",
           "@type": "BreadcrumbList",
           "itemListElement": ${JSON.stringify([{
            "@type": "ListItem",
            "position": 1,
            "item": { "@id": `${baseUrl}`,  "name": `Home`,  "item": `${baseUrl}`  }
          },
          {
            "@type": "ListItem",
            "position": 2,
            "item": { "@id": `${baseUrl}`,  "name": `Contact`,  "item": `${baseUrl}/contact/`  }
          }], null, 2)}
        }`}
        </script>
      </Helmet>
      <section className="contact-wrap contact-form connect">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-wrap">
                <div className="content-wrap">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="contact-title">
              <h1>{staffname ? "Contact " + staffname : props.title}</h1>
              {parse(props.content)}
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="form-field-wrapper">
              {staffemail ? (
                <StaffContactForm
                  to_email_id={staffemail}
                  formtype={staffname ? "Staff Contact" : null}
                  subject={staffname ? "Staff Contact" : null}
                />
              ) : (
                <ContactForm
                  formtype={staffname ? "Staff Contact" : null}
                  subject={staffname ? "Staff Contact" : null}
                />
              )}
            </Col>
            <Col lg={4} className="card-field-wrapper">
              {staffemail ? (
                <StaffCard staffdata={teamdata} waMsg={waMsg} />
              ) : (
                <MemberCard waMsg={waMsg} />
              )}
              <ContactDetails />
            </Col>
          </Row>
        
        </Container>
      </section>
      <section className="feedback-block" onClick={()=>{  setIsModalOpen(true)}}>
        <Container>
          <Row>
            <Col className="feedback text-center">
              <p className="link">We’d love your feedback</p>
             
            </Col>
          </Row>
        </Container>
      </section>
      <FeedBackModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  )
}
export default Connect
