import React from "react"
import parse from "html-react-parser"
import { Container, Row, Col } from "react-bootstrap"
import ContactCard from "../../components/ContactSidebar/ContactSidebarNoStaff"
import "./StaticPageModule.scss"
import Sticky from "react-stickynode"

const StaticPageModule = ({ module, pageData }) => {
  const contact = pageData.Select_Contact

  let waMsg =
    pageData.id === "627c9d57d9347ca6fe43d80b"
      ? "Hi there, I would like to file a complaint"
      : ""

  return (
    <div className="container static-module-wrap">
      <Row>
        <Col md={12} lg={8} xl={8}>
          <div className="static-content">{parse(module.Static_Content)}</div>
          <div className="stop-sticky"></div>
        </Col>
        <Col lg={4} xl={4}>
          <div className="contactCard">
            <Sticky top=".header" bottomBoundary=".stop-sticky">
              {" "}
              <ContactCard waMsg={waMsg} />
            </Sticky>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default StaticPageModule
