import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useStaticQuery, graphql  } from "gatsby";

import MObBrand01 from "../../images/awards/mob-brand01.jpg";
import MObBrand02 from "../../images/awards/mob-brand02.jpg";

import TabBrand01 from "../../images/awards/tab-brand01.jpg";
import TabBrand02 from "../../images/awards/tab-brand02.jpg";
import TabBrand03 from "../../images/awards/tab-brand03.jpg";
import TabBrand04 from "../../images/awards/tab-brand04.jpg";

import Brand01 from "../../images/awards/brand01.jpg";
import Brand02 from "../../images/awards/brand02.jpg";
import Brand03 from "../../images/awards/brand03.jpg";
import Brand04 from "../../images/awards/brand04.jpg";
import Brand05 from "../../images/awards/brand05.jpg";
import Brand06 from "../../images/awards/brand06.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import { AnimationOnScroll } from "react-animation-on-scroll";
import GetGatsbyImage from "../common/site/get-gatsby-image"

import "./Brands.scss";

const Brands = (props) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Lenders_We_Work_With {
            Module_Description
            Module_Title
            id
            Module_Images {
              id
              Add_Image {
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { Module_Description, Module_Title, Module_Images } = props.module ? props.module : data.glstrapi.globalConfig.Lenders_We_Work_With
  
  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <section className="brands-section">
        <Container>
          <Row>
            <Col>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInUp"
              >
                <div className="heading">
                  <h2>{Module_Title}</h2>
                  <p>{Module_Description}</p>
                </div>
                <Slider {...settings}>
                  {Module_Images.map((item, index) => (
                    <div className="brands" key={item.id}>
                      <div className="brands-img zoom">
                        <picture>
                          <GetGatsbyImage
                          image={item.Add_Image}
                          fallbackalt="brand logo"
                        />
                        </picture>
                      </div>
                    </div>
                  ))}
                </Slider>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
};
export default Brands;
