import React from "react"
import Select from 'react-select'
export default function CurrConvertLayout(props) {


  let values =   [{value:'AED', label:'AED'},{value:'GBP', label:'GBP'},   {value:'EUR', label:'EUR'},
  {value:'USD', label:'USD'},
  {value:'RUB', label:'RUB'},
  {value:'INR', label:'INR'}
  ]
  //let values = props.currncy_values? props.currncy_values: ['AED','GBP','EUR','USD','RUB','INR']
  /*if(typeof process.env?.GATSBY_CurrencyConverter !== undefined){
    values =  process.env.GATSBY_CurrencyConverter.split(',');
  }*/
  let currVal = values[0]
  const handleCurrencyConverter = event => {
    //set localstorage value

    if (typeof window !== 'undefined') {
      localStorage.setItem('currTo', event.value.trimStart());
      props.parentCallback(event.value.trimStart())
    }
  }

  if (typeof window !== 'undefined') {
    if(localStorage.getItem('currTo') != null){
      currVal = localStorage.getItem('currTo')
    }
  }

  return (
    <div className={props?.outterClass}>
       <Select 
       value={currVal}
       defaultValue={currVal}
       options={values} 
       className="react-select"  
       classNamePrefix="react-select" 
       onChange={handleCurrencyConverter} 
       isSearchable={false}
       isClearable={false}
       placeholder={currVal}
       />
      { 
      /*<select name="currencies" className={`${props?.selectClass}`} id="currencies" onChange={handleCurrencyConverter}>
          {values.map((val, i) => { 
            let myselect = '';
            if(currVal == val) { myselect = "selected"; }
            return (<option value={val} selected={myselect} key={i}>{val}</option>) })}
      </select>
          */
          }
  </div>
  )
}